export type PresetPrefix = 'square' | 'vertical' | 'horizontal' | 'normal'
export const sizes = [
  2000,
  1600,
  800,
  400,
  200,
  100
]
const aspectRatios: Record<PresetPrefix, number | undefined> = {
  square: 1 / 1,
  vertical: 1 / 1.9,
  horizontal: 1.9 / 1,
  normal: undefined,
}

export const presets: Record<string, string> = {
  ...generatePresets(sizes),
  placeholder: presetPlaceholder(),
  ...Object.keys(aspectRatios).filter(Boolean).reduce((presets, prefix) => ({
    ...presets,
    ...generatePresets(sizes, prefix as PresetPrefix),
    [`${prefix}-placeholder`]: presetPlaceholder(aspectRatios[prefix as PresetPrefix]),
  }), {})
}

function generatePresets(sizes: number[], presetPrefix?: PresetPrefix) {
  return sizes.reduce<Record<string, string>>((presets, size) => {
    if (presetPrefix) {
      presets[`${presetPrefix}-${size}`] = presetSize(size, aspectRatios[presetPrefix])
    } else {
      presets[`${size}`] = presetSize(size)
    }
    return presets
  }, {})
}

function presetSize(wSize: number, aspectRatio?: number) {
  if (!aspectRatio) {
    return `tr:w-${wSize}`
  }
  const hSize = Math.round(wSize / aspectRatio)
  return `tr:w-${wSize},h-${hSize},fo-auto`
}

function presetPlaceholder(aspect?: number) {
  const size = 100
  const preset = aspect ? presetSize(size, aspect) : presetSize(size)
  return preset + ',bl-10,q-50'
}
